export const react_domain = `${window.location.origin}`;

export const broker_redirect_url = "https://connectbox.tradestreet.in/backend/";
export const socket_Url = `https://connectbox.tradestreet.in:1001`;
export const broker_backend = `https://connectbox.tradestreet.in/signal/broker-signals`;
export const base_url = `https://connectbox.tradestreet.in/backend/`;



// export const broker_redirect_url = "https://connect.thealgo.services/backend/";
// export const socket_Url = `https://connect.thealgo.services:1001`;
// export const broker_backend = `https://connect.thealgo.services/signal/broker-signals`;
// export const base_url = `https://connect.thealgo.services/backend/`;




// export const base_url = `http://localhost:7700/`;



