import React from 'react'

function Dashboard() {
  return (
    <div>
      <h1>WELCOME SUPERADMIN!</h1>
    </div>
  )
}

export default Dashboard