import React from 'react';

function Loader() {
    return (
        <div className='loader-container'>
        <div className="loader" style={{
            
        }}>
          
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__bar"></div>
  <div className="loader__ball"></div>
</div>
</div>   
    );
}

export default Loader;


